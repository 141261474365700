<div class="body-cont">
    <div class="ticket-system">
        <div class="top">
            <div class="printer">
            </div>
            <div class="receipts-wrapper">
                <div class="receipts">
                    <div class="receipt">
                        <img class="airliner-logo" src="assets/img/zt-01.svg" alt="">
                        <div class="route">
                            <h3>{{ 'SUCCESSPAYMENT.TITLE' | translate }}</h3>
                            <h3>{{ 'SUCCESSPAYMENT.SUBTITLE' | translate }}</h3>
                        </div>
                        <div class="details">
                            <div class="item">
                                <div class="item">
                                    <span>{{ 'SUCCESSPAYMENT.REFERENCE' | translate }}</span>
                                    <h6 class="highlight-text">{{data.purchaseId}}</h6>
                                </div>
                                <span>{{ 'SUCCESSPAYMENT.PASSENGERS' | translate }}</span>
                                <ng-container *ngFor="let passenger of data.passengers;">
                                    <h6>{{passenger.given_name}} {{passenger.family_name}}</h6>
                                </ng-container>
                            </div>

                            <div class="item">
                                <span>{{ 'SUCCESSPAYMENT.DEPARTURE' | translate }}</span>
                                <h6>{{data.trip.start_date | date: 'medium'}}</h6>
                            </div>
                            <div class="item">
                                <span>{{ 'SUCCESSPAYMENT.TICKETINFOTITLE' | translate }}</span>
                                <h6>{{ 'SUCCESSPAYMENT.TICKETINFOEMAIL' | translate }}</h6>
                                <h6 class="highlight-text my-2">{{data.passengers[0].email}}</h6>
                            </div>
                            <div class="item mt-2">
                                <h6>{{ 'SUCCESSPAYMENT.TICKETINFO' | translate }}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="receipt footer">
                        <h5>{{ 'SUCCESSPAYMENT.TOTAL' | translate }}</h5>
                        <div class="description">
                            <h5>${{data.amount}} <span class="currency">MXN</span></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <a (click)="close()">{{ 'SUCCESSPAYMENT.GOHOME' | translate }}</a>
</div>