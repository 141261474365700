<div class="row">
    <div class="col-sm-12 col-md-3 country-list">
        <lib-country-list [selectedCountryCode]="'MX'" [selectedCountryConfig]="selectedCountryConfig" [countryListConfig]="countryListConfig" (onCountryChange)="onCountryChange($event)"></lib-country-list>
    </div>
    <div class="col-sm-12 col-md-9">
        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{ 'PHONENUMBER' | translate }}</mat-label>
            <input [formControl]="control" autocomplete="tel" matInput>
            <mat-error *ngIf="control?.errors && control?.errors?.['required']">Field
                is
                required</mat-error>
        </mat-form-field>
    </div>
</div>
