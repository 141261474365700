import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

const providers = [
  { provide: 'CONFIGURATION', useValue: environment },
  { provide: 'API_URL', useValue: environment.apiConfig.baseUrl },
  { provide: 'ANALYTICS_ENABLED', useValue: environment.analyticsEnabled },
  { provide: 'HOME-URL', useValue: environment.apiConfig.homeUrl }
];

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));
