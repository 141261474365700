<div *ngFor="let slice of slices" class="row fly-border">
    <div class="col-sm-12 col-md-4 center-elements">
        <div class="departure-info">
            <span class="origin-destination">{{slice.origin.city_name}} -
                {{slice.destination.city_name}}</span>
            <span class="fly-time">{{slice.segments[0].departing_at | date: 'MMM d, y'}}</span>
        </div>
    </div>
    <div class="col-sm-12 col-md-3 center-elements">
        <div class="fly-time-info">
            <span class="fly-time-bold">{{slice.segments[0].departing_at | date: 'h:mm a'}} -
                {{slice.segments[0].arriving_at | date: 'h:mm a'}} <sup>{{ maybeNextDay(slice.segments[0].departing_at, slice.segments.at(-1)?.arriving_at) }}</sup></span>
            <div class="airlines mt-2">
          <div *ngIf="slice.kind === 'flight'; else elseTemplate">
            <img class="avatar-fly" [src]="slice.segments[0].operating_carrier.logo_symbol_url" alt="carrier">
          </div>
          <ng-template #elseTemplate>
            <span class="train-icon">🚂</span>
          </ng-template>
                <span class="detail-airline ml-2 ">{{slice.segments[0].operating_carrier.name}}</span>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-2 stops center-elements">
        <div class="stops-information">
            <span>{{'STOPS' | translate }}: {{slice.stop_segments.length}}</span>
            <ng-container *ngFor="let stop of slice.stop_segments">
                <p class="fly-time">{{stop.origin.iata_code}}</p>
            </ng-container>

        </div>
    </div>
    <div class="col-sm-12 col-md-3 center-elements">
        <div class="hours-city-info">
          <span class="origin-destination">{{getDuration(slice.duration)}}</span>
            <span>{{slice.origin.iata_code}} -
                {{slice.destination.iata_code}}</span>
        </div>
    </div>
</div>
