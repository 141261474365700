import { Component, OnInit } from '@angular/core';
import { LocaleService } from './services/locale.service';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'zumo-app';
  constructor(private _languageService: LanguageService, private _localeService: LocaleService) { }

  ngOnInit(): void {
    const language = this._localeService.getLocale();
    this._languageService.switchLanguage(language)
  }
}
