import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlyInformationComponent } from './components/fly-information/fly-information.component';
import { FlyInformationCardComponent } from './components/fly-information-card/fly-information-card.component';
import { MatCardModule } from '@angular/material/card';
import { SpinnerDialogComponent } from './components/spinner-dialog/spinner-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FooterComponent } from './components/footer/footer.component';
import { MatSelectModule } from '@angular/material/select';
import { CustomDatepickerComponent } from './components/custom-datepicker/custom-datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { NgxCountriesDropdownModule } from 'ngx-countries-dropdown';
import { TimePipe } from './pipes/time.pipe';
import { MatNativeDateModule } from '@angular/material/core';
import { FlightPassengersComponent } from './components/flight-passengers/flight-passengers.component';
import { MatMenuModule } from '@angular/material/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormControlPipe } from './pipes/form-control.pipe';
import { PopUpSignUpComponent } from './components/pop-up-sign-up/pop-up-sign-up.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    FlyInformationComponent,
    FlyInformationCardComponent,
    SpinnerDialogComponent,
    DropdownComponent,
    FooterComponent,
    CustomDatepickerComponent,
    PhoneNumberComponent,
    TimePipe,
    FlightPassengersComponent,
    FormControlPipe,
    PopUpSignUpComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgxCountriesDropdownModule,
    MatNativeDateModule,
    MatMenuModule,
    OverlayModule,
    TranslateModule
  ],
  exports: [
    FlyInformationComponent,
    FlyInformationCardComponent,
    DropdownComponent,
    FooterComponent,
    CustomDatepickerComponent,
    PhoneNumberComponent,
    TimePipe,
    FlightPassengersComponent,
    FormControlPipe,
    PopUpSignUpComponent
  ]
})
export class SharedModule { }
