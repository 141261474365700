<div *ngIf="!isLoading" class="p-4 m-0 row">
  <div class="col-sm-12 col-md-10">
    <app-fly-information [slices]="slices" [trips]="trips" [trip]="data" [amount]="amount"
      (closeModal)="close()"></app-fly-information>
  </div>
  <div class="text-center col-sm-12 col-md-2 vertical-align last-column">
    <div>
      <h2 class="special-price-title">{{'SPECIALTITLECARD' | translate}}</h2>
    </div>
    <div>
      <h2 class="amount">${{amount | number : '1.0-0'}} <span class="currency">MXN</span></h2>
    </div>
    <div>
      <h5 class="text-orange">{{'SPECIALSLOGANCARD' | translate}}</h5>
    </div>
    <div><button class="book-btn my-2 text-center" (click)="bookNow()" mat-fab extended color="primary">
        {{'FLYNOW!' | translate}}
      </button></div>
  </div>
</div>
