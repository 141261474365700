import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { WINDOWS_ROOT_OBJECT } from 'src/app/const/const';
import { SpinnerDialogService } from 'src/app/services/spinner-dialog.service';
import { TripService } from 'src/app/services/trip.service';
import { Offers } from 'src/app/shared/models/offers';
import { Trip } from 'src/app/shared/models/trip';
import { Slice } from 'src/app/shared/models/slice';
import { Luggage } from 'src/app/shared/models/luggage';
import { MetaPixelService } from 'src/app/services/meta-pixel.service';

@Component({
  selector: 'app-trip-dialog',
  templateUrl: './trip-dialog.component.html',
  styleUrls: ['./trip-dialog.component.scss']
})
export class TripDialogComponent implements OnInit, OnDestroy {
  trips!: Offers[];
  slices!: Slice[];
  // totalPrice!: number;
  amount!: number;
  protected _onDestroy$ = new Subject<void>;
  constructor(
    private dialogRef: MatDialogRef<TripDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Trip,
    public service: TripService,
    private _spinner: SpinnerDialogService,
    @Inject(WINDOWS_ROOT_OBJECT) private window: Window,
    public router: Router,
    private _metaPixelService: MetaPixelService
  ) { }

  ngOnInit(): void {
    this._metaPixelService.track("openModal")
    this.getOffers();
  }

  getOffers() {
    this._spinner.startSpinner();
    this.service.getOffersByTripId(this.data.id)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(result => {
        this.trips = result.results;
        this.amount = result.amount;
        this.slices = result.slices;
        this._spinner.closeSpinner();
      });
  }

  getExpirationDate() {
    // Generate a random number between 1 and 3
    const randomOffset = Math.floor(Math.random() * 3) + 1;

    if (randomOffset > 1) {
      return `${randomOffset} days`;
    } else {
      return `${randomOffset} day`;
    }
  }

  bookNow() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/details-page'])
    );

    this._metaPixelService.track("clickBookNow")

    this.window?.open(`${url}/${this.data.id}`, '_blank');
  }

  close() {
    this.dialogRef.close();
  }

  get isLoading() {
    return this._spinner.isLoading;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

}
