import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Trip } from 'src/app/shared/models/trip';
import { TripDialogComponent } from '../trip-dialog/trip-dialog.component';

@Component({
  selector: 'app-trip-card',
  templateUrl: './trip-card.component.html',
  styleUrls: ['./trip-card.component.scss']
})
export class TripCardComponent {

  @Input() trip!: Trip;
  constructor(private dialog: MatDialog) { }

  openModal(tripId: string) {
    this.dialog.open(TripDialogComponent, {
      data: this.trip,
      ariaModal: true,
      disableClose: false,
      width: '1600px',
      maxHeight: '95vh',
      maxWidth: '90vw',
      panelClass: 'custom-container'
    }).afterClosed().subscribe((response) => {
    });
  }
}
