import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PersonalDetailsFormStateService {
  personalDetailsState!: UntypedFormGroup;
  dialCode = new UntypedFormControl('+52', [Validators.required]);
  constructor(private fb: UntypedFormBuilder) { }

  initializePersonalDetailsForm() {
    this.personalDetailsState = this.fb.group({
      passengersInformation: new UntypedFormArray([this.createPersonalDetailsForm()])
    });
    console.log(this.personalDetailsState);
  }

  createPersonalDetailsForm() {
    const personalDetailsForm = new UntypedFormGroup({
      given_name: new UntypedFormControl('', [Validators.required]),
      family_name: new UntypedFormControl('', [Validators.required]),
      gender: new UntypedFormControl('', [Validators.required]),
      born_on: new UntypedFormControl('', [Validators.required]),
      title: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]),
      phone_number: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]),
      dial_code: new UntypedFormControl('+52', [Validators.required]),
      identity_documents: new UntypedFormGroup({
        unique_identifier: new UntypedFormControl('', [Validators.required]),
        type: new UntypedFormControl('passport', [Validators.required]),
        issuing_country_code: new UntypedFormControl('', [Validators.required]),
        expires_on: new UntypedFormControl('', [Validators.required])
      }),
    });

    return personalDetailsForm;
  }

  addPersonalDetailsForm() {
    // const passengersInfo = this.personalDetailsState.get('passengersInformation') as UntypedFormArray
    this.passengersInfo.push(this.createPersonalDetailsForm());
  }
  removePersonalDetailsForm(i: number) {
    // const passengersInfo = this.personalDetailsState.get('passengersInformation') as UntypedFormArray
    if (this.passengersInfo.length > 1) {
      this.passengersInfo.removeAt(i)
    } else {
      this.passengersInfo.reset();
    }
  }

  get personalDetailsStateForm(): UntypedFormGroup {
    return this.personalDetailsState as UntypedFormGroup;
  }

  get passengersInfo() {
    return this.personalDetailsState.get('passengersInformation') as UntypedFormArray;
  }

  get passengersInfoControls(): UntypedFormGroup[] {
    return (this.personalDetailsState.controls['passengersInformation'] as UntypedFormArray).controls as UntypedFormGroup[];
  }
}
