import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Trip } from '../shared/models/trip';
import { Response } from '../shared/models/response';
import { Offers } from '../shared/models/offers';
import { ResponseOffer } from '../shared/models/response-offer';
import { Countries } from '../shared/models/countries';
import { Airport } from '../shared/models/airport';
import { Passenger } from '../shared/models/passenger';
import { BasicResponse } from '../shared/models/basic-request';

@Injectable({
  providedIn: 'root'
})
export class TripService {

  constructor(private http: HttpClient,
    @Inject('API_URL') private apiUrl: string,) { }

  private _controller = 'api/Trips';

  getTrips(airport: Airport): Observable<Response<Trip>> {
    return this.http.get<Response<Trip>>(`${this.apiUrl}${this._controller}/GetTrips?from=${airport.id}`);
  }

  getOffersByTripId(id: string): Observable<ResponseOffer<Offers>> {
    return this.http.get<ResponseOffer<Offers>>(`${this.apiUrl}${this._controller}/GetOffersByTripId/${id}`);
  }

  getOffersWithPaymentIntentByTripId(id: string, totalPassengers: number, purchase_id?: string): Observable<ResponseOffer<Offers>> {
    let baseUrl = `${this.apiUrl}${this._controller}/GetOffersWithPaymentIntentByTripId/${id}`;
    const url = new URL(baseUrl);
    if (totalPassengers > 1) {
      url.searchParams.append('adults_qty', totalPassengers.toString());
    }
    if (purchase_id) {
      url.searchParams.append('purchase_id', purchase_id);
    }
    return this.http.get<ResponseOffer<Offers>>(url.toString());
  }

  getNationalities(): Observable<Response<Countries>> {
    return this.http.get<Response<Countries>>(`${this.apiUrl}api/Countries/GetCountries`);
  }

  getAirports(): Observable<Response<Airport>> {
    return this.http.get<Response<Airport>>(`${this.apiUrl}api/Airport/GetAirports`);
  }

  validatePhoneNumber(passenger: BasicResponse<Passenger>): Observable<any> {
    return this.http.post(`${this.apiUrl}validate`, passenger);
  }

  applyCoupon(body: any): Observable<any> {
    return this.http.post(`${this.apiUrl}api/promo/apply`, body).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}
