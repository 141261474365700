import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private translate: TranslateService) { }

  // initDefaultLanguage() {
  //   const browserLang = navigator.language.split('-')[0];  // Get browser language (e.g., 'en', 'fr')
  //   const defaultLang = ['en', 'es'].includes(browserLang) ? browserLang : 'en';
  //   this.translate.setDefaultLang(defaultLang);
  //   this.translate.use(defaultLang);
  // }

  // Switch language dynamically
  switchLanguage(lang: string) {
    this.translate.use(lang);
  }
}
