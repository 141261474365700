<div class="header row">
    <div class="col-6 px-2 py-2 container-logo">
        <img class="logo" src="assets/img/zt-01.svg" alt="">
    </div>
    <div class="col-6 slogan">
        <div>
            <h1 id="slogan1">{{ 'SLOGAN1' | translate }}</h1>
            <h2 id="slogan2">{{ 'SLOGAN2' | translate }}</h2>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="location-dropdown">
        <h3>{{ 'FROM' | translate }}</h3>
        <app-home-dropdown [airports]="airports" (valueChanged)="onDropdownValueChange($event)">
        </app-home-dropdown>
        <h3 class="to">{{ 'TO' | translate }}</h3>
    </div>
    <div class="grid-container responsive-grid">
        <app-trip-card class="card" [trip]="trip" *ngFor="let trip of trips"></app-trip-card>
    </div>
</div>
<app-footer></app-footer>
