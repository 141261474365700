<mat-card class="example-card" (click)="openModal(trip.id)">
    <img class="img" mat-card-image src="{{trip.coverUrl}}" alt="">
    <mat-card-content class="card-content">
        <div class="row">
            <div class="col-7 text-left">
                <h4 class="font-weight">{{trip.tripName}}</h4>
                <h5 class="subtitle">{{trip.subtitle}}</h5>
                <h6>{{trip.start_date | date: 'dd/MMM': "UTC" }} - {{trip.end_date | date: 'dd/MMM' : "UTC" }}</h6>
            </div>
            <div class="col-5 text-right">
              <h6>{{trip.flights_included}} {{(trip.flights_included === 1 ? 'FLIGHT' : 'FLIGHTS') | translate }} {{'INCLUDES_TRAINS' | translate }} {{trip.trains_included}} {{(trip.flights_included === 1 ? 'TRAIN' : 'TRAINS') | translate }}</h6>
              <h4 class="font-weight"><span class="from">desde</span> ${{ trip.approximatePrice | number: '1.0-0' }} <span class="currency">MXN</span></h4>
            </div>
        </div>
    </mat-card-content>
</mat-card>
