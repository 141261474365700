<div class="modal-background">
    <div class="container-pop-up">
        <button class="btn-close-icon" (click)="$event.stopPropagation(); close();" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
        <img class="logo" src="assets/img/zt-01.svg" alt="">
        <h2 class="mt-4">{{ 'SIGNUP.TITLE' | translate }}</h2>
        <p>{{ 'SIGNUP.DESCRIPTION' | translate }}</p>
        <mat-form-field floatLabel="always" appearance="outline">
            <input placeholder="Enter email here" [formControl]="subscribeForm" matInput>
            <mat-error *ngIf="subscribeForm?.errors && subscribeForm.errors?.['required']">Field
                is
                required</mat-error>
        </mat-form-field>
        <p class="privaci-policy gray-text">{{ 'SIGNUP.PRIVACYPOLICYDESC' | translate }} <a
                href="https://s3.amazonaws.com/zumo.travel/privacy-policy.pdf" target="_blank">{{ 'PRIVACYPOLICY' |
                translate }}</a>.
        </p>
        <button [disabled]="subscribeForm.invalid" class="book-btn my-2 text-center" (click)="redirect()" mat-fab
            extended>
            {{ 'SIGNUP.BTNACCEPT' | translate }}
        </button>
    </div>
</div>