import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PopUpSignUpService } from 'src/app/services/pop-up-sign-up.service';

@Component({
  selector: 'app-pop-up-sign-up',
  templateUrl: './pop-up-sign-up.component.html',
  styleUrls: ['./pop-up-sign-up.component.scss']
})
export class PopUpSignUpComponent implements OnInit {
  subscribeForm!: UntypedFormControl;
  constructor(private dialogRef: MatDialogRef<PopUpSignUpComponent>,
    private popUpService: PopUpSignUpService) {
  }

  ngOnInit(): void {
    this.subscribeForm = new UntypedFormControl('', [Validators.required, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]);
  }

  redirect() {
    this.popUpService.subscribe(this.subscribeForm.value)
      .subscribe({
        next: (result) => {
          this.dialogRef.close();
        },
        error: error => {
          console.log(error);
        }
      })
  }

  close() {
    this.dialogRef.close();
  }
}
