<mat-form-field floatLabel="always" appearance="outline">
    <mat-label>{{labelText}}</mat-label>
    <input matInput [matDatepicker]="dp" [formControl]="control" [min]="minDate" [max]="maxDate"
        placeholder="DD/MM/YYYY">
    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp></mat-datepicker>
    <mat-error *ngIf="control?.errors && control?.errors?.['required']">Field
        is
        required</mat-error>
</mat-form-field>
