<div class="body-bg">
    <div class="header row">
        <div class="col-6 px-4 py-2">
            <img class="logo" (click)="goToHome()" src="assets/img/zt-01.svg" alt="">
        </div>
    </div>
    <div *ngIf="!isGettingInfo" class="container-fluid">
        <mat-card class="example-card">
            <mat-card-content class="card-content">
                <div class="row">
                    <div class="col-sm-12 col-md-6" style="padding: 3rem;">
                        <div style="background-color: white; height: 100%;">
                            <app-fly-information [slices]="slices" [trips]="trips" [trip]="trip" [isFromDetails]="true"
                                [amount]="amount" (applyCoupon)="applyCoupon($event)"></app-fly-information>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 container-personal-details">
                        <app-flight-passengers (removePassengers)="removePassengers()"
                            (closeAddRemovePassengers)="updateTripInfo()"
                            (addPassengers)="addPassengers()"></app-flight-passengers>
                        <mat-accordion>
                            <mat-expansion-panel class="panel-personal-details" [expanded]="step === 0"
                                (opened)="setStep(0)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ 'PERSONALDETAILS' | translate }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ng-container *ngFor="let control of passengersInfoControls; let i = index;">
                                    <div class="container mb-2">
                                        <strong class="mb-4">  {{ 'PASSENGER' | translate }} {{i + 1}} </strong>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-4">
                                                <app-dropdown [control]="control.get('title') | formControl"
                                                    [options]="titles" [title]="'Title'"></app-dropdown>
                                            </div>
                                            <div class="col-sm-12 col-md-4">
                                                <mat-form-field floatLabel="always" appearance="outline">
                                                    <mat-label>{{ 'FIRSTNAME' | translate }}</mat-label>
                                                    <input [formControl]="control.get('given_name') | formControl"
                                                        autocomplete="name" matInput (input)="onNameChanged($event)">
                                                    <mat-error
                                                        *ngIf="control.get('given_name')?.errors && control.get('given_name')?.errors?.['required']">Field
                                                        is
                                                        required</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12 col-md-4">
                                                <mat-form-field floatLabel="always" appearance="outline">
                                                    <mat-label>{{ 'LASTNAME' | translate }}</mat-label>
                                                    <input [formControl]="control.get('family_name') | formControl"
                                                        autocomplete="family-name" matInput>
                                                    <mat-error
                                                        *ngIf="control.get('family_name')?.errors && control.get('family_name')?.errors?.['required']">Field
                                                        is
                                                        required</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-6">
                                                <app-dropdown [control]="control.get('gender') | formControl"
                                                    [options]="genders" title="{{ 'GENDER' | translate }}"></app-dropdown>
                                            </div>
                                            <div class="col-sm-12 col-md-6">
                                                <app-custom-datepicker [control]="control.get('born_on') | formControl"
                                                    [minDate]="minDateBirthday" [maxDate]="maxDateBirthday"
                                                    labelText="{{ 'DATEOFBIRTH' | translate }}"></app-custom-datepicker>
                                            </div>
                                        </div>
                                        <mat-form-field floatLabel="always" appearance="outline">
                                            <mat-label>Email</mat-label>
                                            <input [formControl]="control.get('email') | formControl"
                                                autocomplete="email" matInput>
                                            <mat-error
                                                *ngIf="control.get('email')?.errors && control.get('email')?.errors?.['required']">Field
                                                is
                                                required</mat-error>
                                        </mat-form-field>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <app-phone-number (countryCodeChange)="onCountryChange($event, i)"
                                                    [control]="control.get('phone_number') | formControl"></app-phone-number>
                                            </div>
                                        </div>
                                        <strong class="mb-4">{{ 'IDENTITYDOCUMENTS' | translate }}</strong>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-4">
                                                <mat-form-field floatLabel="always" appearance="outline">
                                                    <mat-label>{{ 'NATIONALITY' | translate }}</mat-label>
                                                    <mat-select
                                                        [formControl]="control.get('identity_documents')?.get('issuing_country_code') | formControl">
                                                        <mat-option *ngFor="let nationality of nationalities"
                                                            [value]="nationality.alpha_2_code">
                                                            {{nationality.nationality}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="control.get('identity_documents')?.get('issuing_country_code')?.valid && control.get('identity_documents')?.get('issuing_country_code')?.errors?.['required']">Field
                                                        is
                                                        required</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12 col-md-4">
                                                <mat-form-field floatLabel="always" appearance="outline">
                                                    <mat-label>{{ 'PASSPORTNUMBER' | translate }}</mat-label>
                                                    <input
                                                        [formControl]="control.get('identity_documents')?.get('unique_identifier') | formControl"
                                                        matInput>
                                                    <mat-error
                                                        *ngIf="control.get('identity_documents')?.get('unique_identifier')?.errors && control.get('identity_documents')?.get('unique_identifier')?.errors?.['required']">Field
                                                        is
                                                        required</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12 col-md-4">
                                                <app-custom-datepicker
                                                    [control]="control.get('identity_documents')?.get('expires_on') | formControl"
                                                    [minDate]="minDatePassport"
                                                    labelText="{{ 'EXPIRATIONDATE' | translate }}"></app-custom-datepicker>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                    </div>
                                </ng-container>
                                <mat-action-row>
                                    <button mat-button color="primary" [disabled]="!requiredFielsdAndValid"
                                        (click)="nextStep()">{{ 'BTNNEXT' | translate }}</button>
                                </mat-action-row>
                            </mat-expansion-panel>
                            <mat-expansion-panel [expanded]="step === 1" (opened)="openPaymentForm()"
                                [disabled]="!isPhoneNumberAndFormValid" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ 'PAYMENTDETAILS' | translate }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="payment-condition gray-text">{{ 'PAYMENTCONDITION' | translate }}<a
                                        href="https://s3.amazonaws.com/zumo.travel/terms-and-conditions.html.pdf"
                                        target="_blank">{{ 'TERMSANDCONDITIONS' | translate }}</a> {{ 'AND' | translate }} <a
                                        href="https://s3.amazonaws.com/zumo.travel/privacy-policy.pdf"
                                        target="_blank">{{ 'PRIVACYPOLICY' | translate }}</a>. {{ 'PAYMENTCONDITION2' | translate }}
                                      </p>
                                <duffel-payments class="duffel-payments" #paymentDuffel></duffel-payments>
                                <div class="row total-price mt-4">
                                    <div class="col-sm-12 col-md-6">
                                        <h2 class="orange-color">{{ 'TOTALPRICEUPPER' | translate }}:</h2>
                                        <h2 class="ml-2"> ${{amount | number : '1.2-2'}} <span class="currency">MXN</span></h2>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <img class="safe-payment" src="../../../../../assets/img/safe payment.png"
                                            alt="">
                                    </div>
                                </div>
                                <mat-action-row>
                                    <button mat-button color="warn" (click)="prevStep()">{{ 'BTNPREV' | translate }}</button>
                                </mat-action-row>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<app-footer></app-footer>
