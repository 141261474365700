<div class="container">
    <img class="error-img mb-1" src="assets/img/error.png" alt="">
    <h2>Oh no!</h2>
    <h2 class="mb-4">{{title}}</h2>
    <div class="payment-message-error" *ngIf="isPaymentError">
        <h5 class="mb-2">{{ 'ERRORCOMPONENT.ERROR1' | translate }} <br>
            {{ 'ERRORCOMPONENT.ERROR2' | translate }} <br>
            {{ 'ERRORCOMPONENT.ERROR3' | translate }}
            <br><br>
            {{ 'ERRORCOMPONENT.ERROR4' | translate }}
            <a href="mailto: eduardo@zumo.travel">eduardo@zumo.travel</a>.
        </h5>
        <div class="ref-container" *ngIf="purchaseId">
            <h5>{{ 'ERRORCOMPONENT.REFERENCE' | translate }}: </h5>
            <h5 class="orange-color ml-2">{{purchaseId}}</h5>
        </div>
    </div>
    <button [mat-dialog-close] class="book-btn my-4 text-center" (click)="btnBack()" mat-fab extended>
        {{btnText}}
    </button>
</div>