import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of, Subject, switchMap, takeUntil } from 'rxjs';
import { PaymentService } from 'src/app/services/payment.service';
import { PersonalDetailsFormStateService } from 'src/app/services/personal-details-form-state.service';
import { SpinnerDialogService } from 'src/app/services/spinner-dialog.service';
import { TripService } from 'src/app/services/trip.service';
import { ConfirmPaymentIntentRequest } from 'src/app/shared/models/confirm-payment-intent-request';
import { Countries } from 'src/app/shared/models/countries';
import { IdentityDocuments } from 'src/app/shared/models/identity-documents';
import { Offers } from 'src/app/shared/models/offers';
import { Slice } from 'src/app/shared/models/slice';
import { Passenger } from 'src/app/shared/models/passenger';
import { Payment } from 'src/app/shared/models/payment';
import { Trip } from 'src/app/shared/models/trip';
import { SuccessPaymentDialogComponent } from '../success-payment-dialog/success-payment-dialog.component';
import { SuccessPaymentInformation } from 'src/app/shared/models/success-payment-information';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MetaPixelService } from 'src/app/services/meta-pixel.service';
import { ErrorPageComponent } from '../error-page/error-page.component';
import { BasicResponse } from 'src/app/shared/models/basic-request';
import { WINDOWS_ROOT_OBJECT } from 'src/app/const/const';

@Component({
  selector: 'app-details-page',
  templateUrl: './details-page.component.html',
  styleUrls: ['./details-page.component.scss']
})
export class DetailsPageComponent implements OnInit, OnDestroy {
  @ViewChild('paymentDuffel') paymentDuffel!: ElementRef;
  getInformation$: Subject<void> = new Subject<void>();
  protected _onDestroy$ = new Subject<void>;
  trips!: Offers[];
  slices!: Slice[];
  trip!: Trip;
  payment!: Payment;
  step = 0;
  amount!: number;
  purchaseId!: string;
  passenger!: Passenger;
  genders = [{ id: "f", value: "Female" }, { id: "m", value: "Male" }];
  titles = [{ id: "mr", value: "Mr" }, { id: "ms", value: "Ms" }, { id: "mrs", value: "Mrs" }, { id: "miss", value: "Miss" }, { id: "dr", value: "Dr" }];
  nationalities!: Countries[];
  idPhoneNumberValid = false;
  pixel? = false;
  passengers = 1;
  isGettingInfo = true;
  currentPassengersTotal = 1;
  constructor(
    public tripService: TripService,
    private _paymentService: PaymentService,
    private _route: ActivatedRoute,
    private _spinner: SpinnerDialogService,
    private _personalDetailStateService: PersonalDetailsFormStateService,
    private dialog: MatDialog,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _metaPixelService: MetaPixelService,
    private changeDetector: ChangeDetectorRef,
    @Inject('HOME-URL') private homeUrl: string,
    public router: Router,
    @Inject(WINDOWS_ROOT_OBJECT) private window: Window
  ) { }

  ngOnInit(): void {
    this._personalDetailStateService.initializePersonalDetailsForm();
    this._metaPixelService.track('visitDetailsPage');
    this.getOffers();
  }

  getOffers(totalPassengers = 1, message = '', isUpdate = false) {
    this._spinner.startSpinner(message);
    forkJoin({
      offers: isUpdate ? this.tripService.getOffersWithPaymentIntentByTripId(this.tripId, totalPassengers) : this.tripService.getOffersWithPaymentIntentByTripId(this.tripId, totalPassengers, this.purchaseId),
      nationalities: this.tripService.getNationalities()
    })
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(({ offers, nationalities }) => {
        this.isGettingInfo = false;
        this.nationalities = nationalities.results;
        this.trips = offers.results;
        this.trip = offers.trip;
        this.slices = offers.slices;
        this.amount = offers.amount;
        this.payment = offers.payment;
        this.purchaseId = offers.purchaseId;
        this._spinner.closeSpinner();
        this.changeDetector.detectChanges();
        this.createDuffelComponent();
      });
  }

  createDuffelComponent() {
    //Checar porque luego truena esta parte
    this.paymentDuffel.nativeElement.render({
      paymentIntentClientToken: this.payment.client_token,
      payment_intent_id: this.payment.id,
    });
    this.paymentDuffel.nativeElement.addEventListener('onSuccessfulPayment', this.onSuccessfulPayment.bind(this));
    this.paymentDuffel.nativeElement.addEventListener('onFailedPayment', this.onFailedPayment.bind(this));
  }

  onSuccessfulPayment($event: any) {
    this._spinner.startSpinner();
    this._metaPixelService.track('purchaseComplete');
    let passengers = this.passengersInfo.getRawValue();
    passengers = passengers.map(item => {
      return {
        born_on: formatDate(item.born_on, "yyyy-MM-dd", 'en-US'),
        email: item.email,
        family_name: item.family_name,
        gender: item.gender,
        given_name: item.given_name,
        title: item.title,
        phone_number: `${item.dial_code}${item.phone_number}`,
        identity_documents: Array<IdentityDocuments>({ ...item.identity_documents, expires_on: formatDate(item.identity_documents.expires_on, "yyyy-MM-dd", 'en-US') })
      }
    });

    var request: ConfirmPaymentIntentRequest = {
      paymentIntent: this.payment.id,
      purchaseId: this.purchaseId,
      passengers: passengers
    }
    this._paymentService.confirmPaymentIntent(request)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe({
        next: (result) => {
          this._spinner.closeSpinner();
          if (result.resultsNotFound === false) {
            this.openModal(result.purchaseId, request.passengers[0]);
          }
        },
        error: error => {
          this._spinner.closeSpinner();
          this.openErrorModal();
          console.log(error);
        }
      })
  }

  openModal(purchaseId: string, passenger: Passenger) {
    const successPayInfo: SuccessPaymentInformation = {
      purchaseId: purchaseId,
      amount: this.amount,
      passengers: Array<Passenger>(passenger),
      trip: this.trip

    }
    this.dialog.open(SuccessPaymentDialogComponent, {
      data: successPayInfo,
      ariaModal: true,
      disableClose: true,
      panelClass: 'success-payment',
      backdropClass: 'success-payment-bd'
    }).afterClosed().subscribe(() => {
      this._router.navigate(['/home'])
    });
  }

  onFailedPayment($event: any) {
    this.dialog.open(ErrorPageComponent, {
      data: {
        title: "Something were wrong!"
      },
      disableClose: true,
      panelClass: 'error-dialog-container',
      maxHeight: '85vh',
    });
    console.log('error', $event);
  }

  setStep(index: number) {
    this.step = index;
  }

  openPaymentForm() {
    this._spinner.startSpinner();
    this.idPhoneNumberValid = false;
    this.step = 1;
    this.validatePhoneNumber().subscribe(result => {
      if (result.valid) {
        this.idPhoneNumberValid = result.valid;
      } else {
        this.idPhoneNumberValid = false;
        this.prevStep();
        this._snackBar.open("Your phone number is not valid", undefined, { duration: 5000, panelClass: 'snackbar-error', verticalPosition: 'top' });
      }
      this._spinner.closeSpinner();
    });
  }

  nextStep() {
    this.step++;
    this._metaPixelService.track('clicksNext');
  }

  validatePhoneNumber() {
    let passengers = this.passengersInfo.getRawValue();
    passengers = passengers.map(item => {
      return {
        ...item,
        born_on: formatDate(item.born_on, "yyyy-MM-dd", 'en-US'),
        phone_number: `${item.dial_code}${item.phone_number}`
      }
    });
    var data = <BasicResponse<Passenger>>{
      data: passengers
    }
    return this.tripService.validatePhoneNumber(data);
  }
  onCountryChange(value: any, index: number) {
    this.passengersInfo.controls[index]?.get('dial_code')?.patchValue(value);
  }

  prevStep() {
    this.step--;
  }

  openErrorModal() {
    this.dialog.open(ErrorPageComponent, {
      data: {
        title: "Something happened but we are working on it!",
        isPaymentError: true,
        purchaseId: this.purchaseId
      },
      panelClass: 'error-dialog-container',
      width: '700px',
      maxWidth: '90vh',
      maxHeight: '85vh',
      disableClose: true
    });
  }

  onNameChanged(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    if (!this.pixel) {
      this._metaPixelService.track('startFillingForm');
      this.pixel = true
    }
    // You can also track this event with the Facebook Pixel service
    // this.fbPixelService.track('InputChanged', { inputValue });
  }

  goToHome() {
    this.window?.open(this.homeUrl, '_blank');
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  removePassengers() {
    this.passengers--;
    this._personalDetailStateService.removePersonalDetailsForm(this.passengers - 1);
  }

  addPassengers() {
    this._spinner.startSpinner();
    this.passengers++;
    this._personalDetailStateService.addPersonalDetailsForm();
    this._spinner.closeSpinner();
  }

  updateTripInfo() {
    if (this.currentPassengersTotal !== this.passengers) {
      this.currentPassengersTotal = this.passengers;
      this.getOffers(this.passengers, 'Getting your new offer!', true);
    }
  }

  applyCoupon(coupon: string) {
    this._spinner.startSpinner('Getting your new offer!');
    const reques = {
      purchase_id: this.purchaseId,
      discount_code: coupon
    };
    this.tripService.applyCoupon(reques).pipe(switchMap(x => {
      if (x) {
        return this.tripService.getOffersWithPaymentIntentByTripId(this.tripId, this.passengers, this.purchaseId)
      } else {
        return of();
      }
    })).subscribe({
      next: (offers) => {
        this.trips = offers.results;
        this.trip = offers.trip;
        this.slices = offers.slices;
        this.amount = offers.amount;
        this.payment = offers.payment;
        this.purchaseId = offers.purchaseId;
        this._spinner.closeSpinner();
      },
      error: error => {
        console.log(error);
        this._spinner.closeSpinner();
      }
    })
  }

  get isLoading() {
    return this._spinner.isLoading;
  }

  get tripId() {
    return this._route.snapshot.params["id"];
  }

  get personalDetailsStateForm(): UntypedFormGroup {
    return this._personalDetailStateService.personalDetailsStateForm as UntypedFormGroup;
  }

  get passengersInfo(): UntypedFormArray {
    return this._personalDetailStateService.passengersInfo as UntypedFormArray;
  }

  get passengersInfoControls(): UntypedFormGroup[] {
    return this._personalDetailStateService.passengersInfoControls as UntypedFormGroup[];
  }

  get requiredFielsdAndValid() {
    return this.personalDetailsStateForm.valid;
  }

  get minDateBirthday() {
    return new Date(1850, 1, 1);
  }

  get maxDateBirthday() {
    return new Date();
  }

  get minDatePassport() {
    return new Date();
  }

  get isPhoneNumberAndFormValid() {
    return this.personalDetailsStateForm.valid && this.idPhoneNumberValid;
  }
}
