import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  getLocale(): string {
    let userLang = navigator.languages[0] || 'en'; // Default to English if not detected
    userLang = userLang.split("-")[0];

    if (userLang === 'en') { return 'en-US' }
    if (userLang === 'es') { return 'es-MX' }

    return 'en-US'; // default language
  }
}
