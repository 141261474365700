<footer>
  <div class="container">
    <div class="row">
      <div class="col-12 footer-title">
        <p #p1>{{'FOOTERTITLE1' | translate }}</p>
      </div>
      <div class="col-12 footer-subtitle">
        <p style="font-size: 1.5em; text-align: center;">{{'FOOTERCOL1' | translate }}</p>
        <p style="font-size: 1.5em; text-align: center;">{{'FOOTERCOL2' | translate }}</p>
        <p style="font-size: 1.5em; text-align: center;">{{'FOOTERCOL3' | translate }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 footer-logos center">
        <p>{{'SAFEPAYMENTS' | translate }}</p>
        <img src="assets/img/footer/stripe.png" style="width: 100px;" />
      </div>
      <div class="col-sm-12 col-md-4 footer-logos center">
        <p style="margin-right: 20px;">{{'ACCEPT' | translate }}</p>
        <img src="assets/img/footer/amex.png" style="width: 20px;" />
        <img src="assets/img/footer/visa.png" style="width: 50px;" />
        <img src="assets/img/footer/mc.png" style="width: 50px;" />
      </div>
      <div class="col-sm-12 col-md-4 footer-socials center">
        <p>{{'FOLLOWUS' | translate }}</p>
        <a href="https://www.instagram.com/zumo.travel" target="_blank">
          <img src="assets/img/footer/instagram.png" />
        </a>
      </div>
    </div>
    <div class="center footer-bottom">
      <p>© 2024 Zumo Travel OÜ</p>
      <a href="https://s3.amazonaws.com/zumo.travel/terms-and-conditions.html.pdf" target="_blank">{{'TERMSANDCONDITIONS' | translate }}</a>
      <a href="https://s3.amazonaws.com/zumo.travel/privacy-policy.pdf" target="_blank">{{'PRIVACYPOLICY' | translate }}</a>
    </div>
  </div>
</footer>
