import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './modules/home/_components/home/home.component';
import { PromoComponent } from './modules/promo/_components/promo/promo.component';
import { LandingComponent } from './modules/landing/_components/landing/landing.component';
import { DetailsPageComponent } from '@modules/details-page/_components/details-page/details-page.component';
import { ErrorPageComponent } from '@modules/details-page/_components/error-page/error-page.component';

const routes: Routes = [
  { path: "promo", component: PromoComponent },
  { path: "home", component: HomeComponent },
  { path: "details-page/:id", component: DetailsPageComponent },
  { path: "landing", component: LandingComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: '', redirectTo: "/landing", pathMatch: "full" },
  { path: "**", redirectTo: "/landing" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
